import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      user: null,
      showAddCarModal: false
    };
  },

  getters: {
    user: (state) => state.user,
    showAddCarModal: (state) => state.showAddCarModal
  },

  mutations: {
    user(state, user) {
      state.user = user
    },
    
    showAddCarModal(state, showAddCarModal) {
      state.showAddCarModal = showAddCarModal
    }
  },

  actions: {
    user(context, user) {
      context.commit('user', user);
    },

    showAddCarModal(context, showAddCarModal) {
      context.commit('showAddCarModal', showAddCarModal);
    }
  },

  modules: {
    
  }
})