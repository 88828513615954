import axios from "axios";

export default {
  methods: {
    validateEmail(emailAdress) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Prevents multiple @'s

      if (emailAdress.length == 0) {
        return 'border-gray-300';
      }

      return regex.test(emailAdress) == true ? 'border-green-500' : 'border-red-500';
    },

    checkPassword(password) {
      const number = new RegExp('.*[0-9]');
      const lowerAlphabet = new RegExp('.*[a-z]');
      const upperAlphabet = new RegExp('.*[A-Z]');
      const symbols = new RegExp('.*[!@?#$£*]');
      const length = new RegExp('.{8,}');

      const global = new RegExp('.*[^A-z0-9!@?#$£*]');

      const requirements = {
        hasNumber: false,
        hasLowerAlpha: false,
        hasUpperAlpha: false,
        hasSymbol: false,
      };

      let i = 0;
      let total = 0; // Total = how many requirements have been met

      [number, lowerAlphabet, upperAlphabet, symbols, length].forEach((RegEx) => {
        const requirement = Object.keys(requirements)[i]; // Get correct requirements ID for provided RegEx
        requirements[requirement] = RegEx.test(password); // Has requirement been met

        (requirements[requirement]) === true ? total++ : i++;
      });

      if (password.length == 0) {
        return 'border-gray-300';
      }

      if (password.length >= 8 && global.test(password) !== false) {
        return 'border-green-500'; // Password is fine to use - Doesn't use any Ilegal characters
      }

      const colours = ['border-red-500', 'border-red-500', 'border-orange-500', 'border-amber-500', 'border-yellow-500', 'border-green-500'];

      // console.log(`Total: ${total} & Colour: ${colours[total]}`); - Debug

      return colours[total];
    },

    matchingPassword(password, confirmPassword) {

      if (confirmPassword.length == 0) {
        return 'border-gray-300';
      }

      if (password == confirmPassword) {
        return 'border-green-500';
      } else {
        return 'border-red-500';
      }
    },
    checkIfValidUUID(str) {
      // Regular expression to check if string is a valid UUID
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
      return regexExp.test(str);
    },

    getDateOrdinal(dateNum) {
      let ordinal = "th";

      if (!((dateNum > 3) && (dateNum < 21))) {
        switch (dateNum % 10) {
          case 1: {
            ordinal = "st";
            break;
          }
          case 2: {
            ordinal = "nd";
            break;
          }
          case 3: {
            ordinal = "rd";
            break;
          }
          default: {
            ordinal = "th";
          }
        }
      }

      return ordinal;
    },

    formatDate(date) {
      // 8 Mar 22

      const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      const dateNum = date.getDate();
      const ordinal = this.getDateOrdinal(dateNum);

      return dateNum + ordinal + " " + Months[date.getMonth()] + " " + String(date.getFullYear());

    },

    formatDateTime(date) {
      // 8 Mar 22 @ 07:36 PM

      const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      const dateNum = date.getDate();
      const ordinal = this.getDateOrdinal(dateNum);

      return dateNum + ordinal + " " + Months[date.getMonth()] + " " + String(date.getFullYear()).substr(2, 3) + " @ " + (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + " " + (date.getHours() >= 12 ? 'PM' : 'AM');

    },

    formatYear(date) {
      return date.getFullYear();
    },

    formatTime(time) {
      time = time.split(":");
      
      let result = "";
      let i = 0;

      const segments = ["Hours", "Minutes", "Seconds"];

      time.forEach((timeSegment) => {
        timeSegment = parseInt(timeSegment);

        let build = timeSegment.toString();

        if (timeSegment > 0) {

          if (timeSegment == 1) {
            build =+ " " + (segments[i].slice(0, -1));
          } else {
            build += " " + segments[i];
          }

          if (!((i + 1) == segments.length)) {
            build += ", ";
          }

          result += build;

        }

        i++;
      });

      return result;
    },
    getUserDataFromLocalStorage() {
      let userData = localStorage.getItem('userData');

      if (userData == null) {
        return null;
      }

      return JSON.parse(userData);
    },
    validatePlate(registrationNumber) {
      const regex = /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/;

      if (registrationNumber.length == 0) {
        return 'border-gray-300';
      }

      return regex.test(registrationNumber.toUpperCase()) == true ? 'border-green-500' : 'border-red-500';
    }
  }
}