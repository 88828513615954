import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index';
import shared from '@/shared';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "Manager" */ '../views/Manager.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Verification/Login')
  },
  {

    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "Register" */ '../views/Verification/Register')

  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  let user = store.getters.user;

  if (['login', 'register'].includes(to.name)) {
    next();
    return;
  }

  if (!(shared.methods.checkIfValidUUID(localStorage.getItem('userToken')))) {
    next({name: 'login'});
    return;

  } else {

    if (user === null) {
      let userData = localStorage.getItem('userData');

      if (userData !== null) {
        user = JSON.parse(userData);
        store.replaceState = userData;

      } else {
        next({name: 'login'});
        return;
      }


    }

    // Add AccountRole checks for account level restricted roles (If required)

    if (user !== null) {
      next();
      return;
    }

  }
});


export default router
