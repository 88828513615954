<template>
  <div class="min-h-screen bg-neutral-900">

    <div class="flex items-center justify-center h-full">
      <router-view/>
    </div>
  </div>

</template>

<style>

</style>
