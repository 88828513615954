import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import './assets/tailwind.css'
import '@/axios';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

createApp(App)
  .use(router)
  .use(store)
  .use(Toast, {
    maxToasts: 3,
    newestOnTop: true
  })
  .mount('#app')
